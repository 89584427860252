<template>
  <div class="administrator-manage">
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <div class="col toolbar-title"></div>
        <el-button
          size="small"
          type="primary"
          @click="handleAddAdmin"
        >
          添加成员
        </el-button>
      </template>

      <template slot="table-columns">
        <el-table-column prop="face" label="管理员头像" width="100">
          <template slot-scope="scope">
            <img :src="scope.row.face" class="face-image" alt=""/>
          </template>
        </el-table-column>
        <el-table-column prop="username" label="管理员名称"/>
        <el-table-column prop="real_name" label="管理员真实姓名"/>
        <el-table-column prop="role_name" label="所属角色"/>
        <el-table-column prop="department" label="所属部门"/>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope" v-if="scope.row.founder !== 1">
            <el-button
              size="mini"
              type="primary"
              @click="handleEditAdmin(scope.$index, scope.row)"
            >
              编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDeleteAdmin(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination
        background
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="MixinPageSizes"
        :page-size="tableData.page_size"
        :layout="MixinTableLayout"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
    <el-dialog
      :title="adminForm.id ? '编辑管理员' : '添加管理员'"
      :visible.sync="dialogVisible"
      width="500px"
      @open="handleDialogOpen"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="adminForm"
        :rules="adminRules"
        ref="adminForm"
        label-width="120px"
      >
        <el-form-item label="管理员名称" prop="username">
          <span v-if="adminForm.id">{{ adminForm.username }}</span>
          <el-input
            v-else
            v-model="adminForm.username"
            :maxlength="20"
            clearable
            placeholder="请输入管理员名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="管理员密码" prop="password">
          <el-input
            type="password"
            v-model="adminForm.password"
            :maxlength="20"
            clearable
            :placeholder="
              adminForm.id ? '不修改请留空' : '6-20位数字、英文字母'
            "
          ></el-input>
        </el-form-item>
        <el-form-item label="所属角色" prop="role_id">
          <el-select
            v-model="adminForm.role_id"
            :disabled="adminForm.founder === 1"
            :placeholder="
              adminForm.founder === 1 ? '超级管理员无须选择角色' : '请选择角色'
            "
            clearable
          >
            <el-option
              v-for="item in rolesOptions"
              :key="item.role_id"
              :label="item.role_name"
              :value="item.role_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属部门" prop="department">
          <el-input
            v-model="adminForm.department"
            :maxlength="20"
            clearable
            placeholder="请输入所属部门"
          ></el-input>
        </el-form-item>
        <el-form-item label="管理员真实姓名" prop="real_name">
          <el-input
            v-model="adminForm.real_name"
            :maxlength="20"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="备注信息" prop="remark">
          <el-input
            v-model="adminForm.remark"
            :maxlength="20"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="管理员头像" class="faceName" prop="face">
          <el-upload
            :action="MixinUploadApi"
            :on-success="
              (res) => {
                adminForm.face = res.url;
              }
            "
            :on-remove="
              () => {
                adminForm.face = '';
              }
            "
            :file-list="
              adminForm.face ? [{ name: 'face', url: adminForm.face }] : []
            "
            list-type="picture"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              建议上传jpg/png文件，且不超过1MB
            </div>
          </el-upload>
        </el-form-item>
        <!-- <el-form-item label="超级管理员">
					<el-radio v-model="adminForm.founder" :label="1">是</el-radio>
					<el-radio v-model="adminForm.founder" :label="0">否</el-radio>
        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitAdminForm" size="small"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as API_Auth from "@/api/auth";
import md5 from "js-md5";
import {RegExp} from "@/../ui-utils";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";

export default {
  name: "administratorManage",
  components: {EnTableLayout},
  data() {
    return {
      // 列表loading状态
      loading: false,
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
      },
      // 列表数据
      tableData: {},
      // 管理员表单
      adminForm: {},
      // 管理员表单 规则
      adminRules: {
        username: [
          this.MixinRequired("请输入管理员名称！"),
          {
            validator: (rule, value, callback) => {
              if (value && !/^[0-9A-Za-z]{6,20}$/.test(value)) {
                callback(new Error("由6~20位数字或字母或数字+字母组成，字母区分大小写"));
              } else {
                callback();
              }
            },
          },
        ],
        password: [
          {required: true, message: "请输入管理员密码！", trigger: "bulr"},
          {
            validator: (rule, value, callback) => {
              if (value && !RegExp.password.test(value)) {
                callback(new Error("密码格式错误！"));
              } else {
                callback();
              }
            },
          },
        ],
        department: [this.MixinRequired("请输入所属部门！")],
        role_id: [
          {required: true, message: "请选择所属角色！", trigger: "change"},
        ],
        face: [
          {
            validator: (rule, value, callback) => {
              console.log(rule, value, callback);
              if (!this.adminForm.face) {
                callback(new Error("请上传管理员头像！"));
              } else {
                callback();
              }
            },
          },
        ],
      },
      // 管理员表单 dialog
      dialogVisible: false,
      // 角色列表
      rolesOptions: [],
    };
  },
  watch: {
    "adminForm.id": function (newValue) {
      this.adminRules.username[0].required = !newValue;
      this.adminRules.password[0].required = !newValue;
    },
    "adminForm.founder": function (newValue) {
      this.adminRules.role_id[0].required = newValue === 0;
      if (newValue === 1) this.adminForm.role_id = 0;
    },
  },
  mounted() {
    this.GET_AdministratorList();
    // 获取角色
    API_Auth.getRoleList({page_size: 20000}).then((response) => {
      this.rolesOptions = response.data;
    });
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_AdministratorList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_AdministratorList();
    },

    /** 添加管理员 */
    handleAddAdmin() {
      this.adminForm = {
        founder: 0,
      };
      this.dialogVisible = true;
    },

    /** 编辑管理员 */
    handleEditAdmin(index, row) {
      this.adminForm = this.MixinClone(row);
      this.dialogVisible = true;
    },

    /** 删除管理员 */
    handleDeleteAdmin(index, row) {
      this.$confirm("确定要删除这个管理员吗？", "提示", {type: "warning"})
        .then(() => {
          API_Auth.deleteAdministrator(row.id).then(() => {
            this.$message.success("删除成功！");
            this.GET_AdministratorList();
          });
        })
        .catch(() => {
        });
    },
    /** dialog打开后重置form表单校验结果 */
    handleDialogOpen() {
      setTimeout(() => {
        this.$refs["adminForm"].clearValidate();
      });
    },

    /** 提交管理员表单 */
    submitAdminForm() {
      this.$refs["adminForm"].validate((valid) => {
        if (valid) {
          const {id} = this.adminForm;
          const params = this.MixinClone(this.adminForm);
          if (params.password) params.password = md5(params.password);
          if (!params.real_name) delete params.real_name;
          if (id) {
            // console.log(params)
            API_Auth.editAdministrator(id, params).then((response) => {
              this.dialogVisible = false;
              this.$message.success("修改成功！");
              this.GET_AdministratorList();
            });
          } else {
            // console.log(params)
            API_Auth.addAdministrator(params).then(() => {
              this.dialogVisible = false;
              this.$message.success("添加成功！");
              this.GET_AdministratorList();
            });
          }
        } else {
          this.$message.error("表单填写有误，请核对！");
          return false;
        }
      });
    },

    /** 获取管理员列表 */
    GET_AdministratorList() {
      this.loading = true;
      API_Auth.getAdministratorList(this.params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .faceName .el-form-item__label::before {
  content: "*";
  color: #a91a1a;
  margin-right: 4px;
}

.face-image {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

::v-deep .el-dialog__body {
  padding: 10px 20px;
}
</style>
